<template>
  <node-view-wrapper :id="idSelect" class="calculadora-volume-component">
    <span class="select_value" tabindex="0" @click="editar">{{ node.attrs.resultado ? node.attrs.resultado : "calcular"
    }}</span>
    <v-dialog v-model="dialog" persistent max-width="400">

      <v-card>
        <v-card-title class="mb-3">Calculadora Volume
        </v-card-title>
        <v-card-text class="context_campos">

          <v-text-field label="Valor 1" autofocus autocomplete="off" v-model="value1"></v-text-field>
          <v-text-field label="Valor 2" autocomplete="off" v-model="value2"></v-text-field>
          <v-text-field label="Valor 3" autocomplete="off" v-model="value3"></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="cancelar">Cancelar</v-btn>
          <v-btn color="primary" @click="salvar">Calcular</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  data() {
    return {
      idSelect: "select_" + Math.random().toString(36).substring(2, 9),
      dialog: false,
      value1: "",
      value2: "",
      value3: "",
    };
  },
  components: {
    NodeViewWrapper
  },
  methods: {
    editar() {// event
      // this.$emit('editar', this.node.attrs);
      // console.log("editar", event, this.node.attrs);

      // this.opcoesTmp = this.node.attrs.opcoes.split('|').map(item => ({ "value": item.trim() }));
      this.dialog = true
    },
    salvar() {
      this.dialog = false
      this.updateAttributes({
        value1: this.value1,
        value2: this.value2,
        value3: this.value3,
        resultado: this.calcular()
      });
    },
    calcular() {

      /**
       var valorA = $('#valorA').val().replace(',', '.');
      var valorB = $('#valorB').val().replace(',', '.');
      var valorC = $('#valorC').val().replace(',', '.');

      $('#resultado').val(Math.round((valorA * valorB * valorC * 0.52) * 100) /
        100);
       */
      const valorA = parseFloat(this.value1.replace(',', '.'));
      const valorB = parseFloat(this.value2.replace(',', '.'));
      const valorC = parseFloat(this.value3.replace(',', '.'));

      return Math.round((valorA * valorB * valorC * 0.52) * 100) / 100;
    },
    cancelar() {
      this.dialog = false
    }
  },
  mounted() {
    // const self = this;
    setTimeout(() => {
      // self.select = document.querySelector(`#${self.idSelect}.calculadora-volume-component`);
      // self.input = self.select.querySelector('.calculadora_value');

      // self.input.addEventListener('click', () => {
      //   self.input.focus();
      // });

      // self.input.addEventListener('focus', self.show);

      if (this.node.attrs.value3 == "") {
        this.dialog = true;
      }
    }, 200);
  },

  props: nodeViewProps
};
</script>

<style lang="scss">
.calculadora-volume-component {
  display: inline-block;
  position: relative;
}
</style>
