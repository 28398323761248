import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import Component from "./CalculadoraVolumeComponent.vue";

export default Node.create({
  name: "calculadora-volume-component",

  group: "inline",

  inline: true,
  atom: true,
  selectable: false,

  parseHTML() {
    return [
      {
        tag: "calculadora-volume-component",
      },
    ];
  },

  addAttributes() {
    return {
      value1: {
        default: ""
      },
      value2: {
        default: ""
      },
      value3: {
        default: ""
      },
      resultado: {
        default: ""
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "calculadora-volume-component",
      mergeAttributes(HTMLAttributes)
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },
  
  addCommands() {
    return {
      createCalculadoraVolumeComponent: (options) => ({ commands }) => {
        console.log(options, commands);
        return commands.insertContent({
          type: this.name,
          attrs: {
            // opcoes: "aaa | bbb | ccc | ddd | eee | fff | gggg | ghhhh | iiiii | lllll",
            // selecionado: options.selecionado,
          },
        });
      },
    };
  }
});
